<template>
  <v-container>
    <v-form ref="form">
      <v-card class="mx-auto pa-8 mt-16" max-width="440px" :elevation="10">
        <v-sheet>
          <v-card
            flat
            class="d-flex flex-md-row flex-column justify-center align-center"
          >
            <div class="d-flex justify-center">
              <v-img
                v-if="companyName == 'Panya Factory'"
                max-width="150px"
                contain
                src="@/assets/panya-logo.png"
              ></v-img>
              <v-img
                v-else
                max-width="150px"
                contain
                src="@/assets/logo.png"
              ></v-img>
            </div>
            <div
              class="text-h5 ml-0 ml-md-5"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <!-- {{ $vuetify.lang.t("$vuetify.base.companyName") }} -->
              {{ companyName }}
            </div>
          </v-card>
        </v-sheet>
        <v-divider />
        <v-card-text>
          <div class="text-h6 d-flex justify-center mb-4">
            {{ $vuetify.lang.t("$vuetify.login.title") }}
          </div>

          <v-text-field
            ref="emailField"
            solo
            :rules="userRules"
            required
            data-cy="loginemail"
            :placeholder="$vuetify.lang.t('$vuetify.login.email')"
            v-model="email"
          ></v-text-field>

          <v-text-field
            solo
            ref="passwordField"
            data-cy="loginpassword"
            name="name"
            :rules="passwordRules"
            required
            :placeholder="$vuetify.lang.t('$vuetify.login.password')"
            v-model="password"
            v-on:keyup.enter="loginLocal"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (showPass = !showPass)"
            :type="showPass ? 'text' : 'password'"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mx-auto"
            color="primary"
            data-cy="loginsubmit"
            @click.prevent="loginLocal"
            :disabled="loading"
            :loading="loading"
          >
            {{ $vuetify.lang.t("$vuetify.login.loginSubmit") }}
          </v-btn>
        </v-card-actions>
        <v-divider class="my-5" />
        <v-card-actions>
          <v-spacer />
          <google-sign-in-button
            @onSuccess="onGoogleSignIn"
            :oneTap="false"
            :clientId="googleClientId"
          />
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      googleOauthToken: null,
      showPass: false,
      userRules: [(v) => !!v || "Email is required"],
      passwordRules: [
        (v) => (!!v || this.googleOauthToken ? true : "Password is required"),
      ],
      companyName: process.env.VUE_APP_TITLE,
      loading: false,
      googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    };
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("auth", ["currentUser"]),
    isValidForm() {
      // Validate email
      if (!this.$refs.emailField.validate()) return false;

      // If password is filled or googleOauthToken is present, it's valid
      if (this.password || this.googleOauthToken) return true;

      // If neither is present, set password field as invalid and return false
      this.$refs.passwordField.setErrors(["Password is required"]);
      return false;
    },
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "clearMessage",
    ]),
    ...mapActions("auth", ["updateUserToken"]),
    loginLocal() {
      if (!this.isValidForm) {
        this.addMessage("Please check your input");
        this.loading = false;
        return;
      }

      let user = {
        username: this.email,
        password: this.password,
        googleOauthToken: this.googleOauthToken,
        useCookie: true,
      };
      this.login(user)
        .then(() => {
          this.clearMessage();
          let callBack = this.$route.query.callback;
          if (callBack != null) {
            this.$router.push({
              path: callBack,
            });
          } else {
            this.$router.push(this.$route.query.redirect || "/");
          }
        })
        .catch((error) => {
          if (error.status == 403) {
            //Forbidden
            this.addErrorMessage(
              "Please contact your administrator for access to this module"
            );
          }
          if (error.status == 401) {
            //Unauthorized
            this.addErrorMessage("Invalid Credentials");
          } else if (error.status == 500) {
            this.addErrorMessage(
              "Server network error, please try again later"
            );
          }
        });
    },
    onGoogleSignIn({ idToken, email }) {
      this.email = email;
      this.googleOauthToken = idToken;

      this.$nextTick(() => {
        if (this.isValidForm) {
          this.loginLocal();
        } else {
          this.addMessage("Please check your input");
        }
      });
    },
    login(credential) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$axios
          .post(process.env.VUE_APP_AUTHHOST + "/authenticate", credential, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((resp) => {
            const token = resp.data.token;
            console.info("token " + token);
            const user = resp.data.user;
            if (user != null && localStorage.getItem("locale") != null) {
              localStorage.setItem(
                "locale",
                user.locale == null ? "en" : user.locale
              );
            }

            this.updateUserToken({
              vm: this,
              auth: {
                token: token,
                user: user,
              },
            });

            this.loading = false;
            resolve(resp);
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
  },
};
</script>
